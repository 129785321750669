import 'inter-ui/inter.css'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { toast, Toaster } from 'react-hot-toast'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { SWRConfig } from 'swr'
import { Api } from './api'
import './App.css'
import { Root } from './components/Root'
import { Classes } from './pages/Classes'
import { Paychoice } from './pages/Paychoice'
import { Pricing } from './pages/Pricing'
import { Purchase } from './pages/Purchase'
import { Stripe } from './pages/Stripe'

function App() {
  return (
    <RecoilRoot>
      <HelmetProvider>
        <Helmet titleTemplate="%s - Xoda"></Helmet>
        <SWRConfig
          value={{
            fetcher: (uri, data) => Api.get(uri, data),
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateIfStale: false,
            shouldRetryOnError: false,
            onError: (error) => {
              if (error instanceof Error) {
                /**
                 * @todo Don't know why it can't show up immediately.
                 */
                setTimeout(() => {
                  toast.error(error.message)
                }, 100)
              }
            },
          }}
        >
          <Root>
            <Toaster containerClassName="toast-container" />
            <BrowserRouter>
              <Routes>
                <Route path="classes" element={<Classes />} />
                <Route path="stripe" element={<Stripe />} />
                <Route path="paychoice" element={<Paychoice />} />
                <Route path="purchase/:type/:id" element={<Purchase />} />
                <Route path="pricing" element={<Pricing />} />
              </Routes>
            </BrowserRouter>
          </Root>
        </SWRConfig>
      </HelmetProvider>
    </RecoilRoot>
  )
}

export { App }
