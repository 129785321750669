import { FC, useCallback } from 'react'
import toast from 'react-hot-toast'
import { useRecoilState } from 'recoil'
import { Api } from '../../api'
import { useSwitch } from '../../hooks/switch'
import { useUser } from '../../hooks/user'
import { ReactComponent as ArrowRight2 } from '../../icons/arrow-right-2.svg'
import { gymState } from '../../store/gym'
import { Button } from '../Button'
import { Field } from '../Field'
import { Form, FormProps } from '../Form'
import { Input } from '../Input'
import { Link } from '../Link'
import { Modal } from '../Modal'
import s from './SignInModal.module.css'

const SignInModal: FC<SignInModalProps> = (props) => {
  const { onRequestSignUp, onFinish, onCancel } = props

  const [gym] = useRecoilState(gymState)
  const { signIn } = useUser()
  const { on: opened, close } = useSwitch(true)
  const { on: pending, open: startPending, close: stopPending } = useSwitch()

  const handleRequestClose = useCallback(() => {
    close()
    onCancel?.()
  }, [close, onCancel])

  const handleSubmit: Required<FormProps>['onSubmit'] = useCallback(
    async (fields) => {
      startPending()

      try {
        const body = await Api.post('member_portal/login', fields)
        close()
        await signIn({
          memberId: body.member_id,
          leadId: body.lead_id,
          userId: body.user_id,
          email: fields.email,
          token: body.token,
          firstName: body.first_name,
          lastName: body.last_name,
          gymId: Number(body.gym_id),
        })
        onFinish?.()
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      } finally {
        stopPending()
      }
    },
    [close, onFinish, signIn, startPending, stopPending]
  )

  return (
    <Modal opened={opened} onRequestClose={handleRequestClose}>
      <div className={s.main}>
        <header className={s.header}>
          <h1 className={s.title}>Member portal for {gym?.name}</h1>
          <div className={s['sign-up']}>
            Don’t have an account?{' '}
            <Link underline={false} bold onClick={onRequestSignUp}>
              Sign up
            </Link>
          </div>
        </header>
        <Form onSubmit={handleSubmit}>
          <Field
            label="Email"
            control={
              <Input type="email" name="email" required size={33} autoFocus />
            }
          />
          <Field
            label="Password"
            control={
              <Input type="password" name="password" required size={33} />
            }
          />
          <div className={s.actions}>
            <Button type="submit" pending={pending}>
              Sign in <ArrowRight2 />
            </Button>
            <div className={s.forgot}>
              <Link
                href={`${process.env.REACT_APP_WEB_PORTAL_ORIGIN}/auth/reset-password`}
                target="_blank"
              >
                Forgot your password?
              </Link>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

type SignInModalProps = {
  onRequestSignUp: VoidFunction
  onFinish?: VoidFunction
  onCancel?: VoidFunction
}

export { SignInModal }
export type { SignInModalProps }
