import md5 from 'md5'
import moment from 'moment-timezone'
import { FC, FormEventHandler, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Api } from '../../api'
import s from './Paychoice.module.css'

const Paychoice: FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [method, setMethod] = useState('card')

  const handleMethodChange: FormEventHandler<HTMLFormElement> = (e) => {
    setMethod(
      (e.currentTarget.elements.namedItem('method') as RadioNodeList).value
    )
  }

  const callback = useMemo(() => {
    const cardGuid = searchParams.get('CreditCardGuid')
    const bankGuid = searchParams.get('BankAccountGuid')
    return {
      method: cardGuid ? 'card' : bankGuid ? 'bank' : undefined,
      guid: cardGuid || bankGuid,
    }
  }, [searchParams])

  useEffect(() => {
    ;(async () => {
      if (!callback.guid) {
        return
      }

      const loadingToast = toast.loading('Creating the member')

      try {
        await Api.post('member_portal/members', {
          lead_id: searchParams.get('lead_id'),
          plan_id: searchParams.get('plan_id'),
          pack_id: searchParams.get('pack_id'),
          promo_code: searchParams.get('promo_code'),
          payment: {
            method: 'paychoice',
            paychoice_customer_id: searchParams.get('customer_id'),
            checkout_id: searchParams.get('customer_reference'),
            paychoice_payment_method: callback.method,
            paychoice_payment_method_id: callback.guid,
          },
        })
        toast.success('Congratulations, you can sign in now.')
        navigate('/classes', { replace: true })
      } catch (error) {
        toast.error((error as Error).message)
      } finally {
        toast.dismiss(loadingToast)
      }
    })()
  }, [callback.guid, callback.method, navigate, searchParams])

  if (callback.guid) {
    return null
  }

  const config = {
    account: searchParams.get('username'),
    apiKey: searchParams.get('private_key'),
    timestamp: moment().format('YYYYMMDDHHmmss'),
    amount: '0.00',
    currency: 'AUD',
    returnAction: window.location.href,
  }
  const hash = md5(
    `${config.account}${config.apiKey}${config.timestamp}${config.amount}${config.currency}${config.returnAction}`.toLowerCase()
  )

  return (
    <>
      <Helmet>
        <script
          src={`${process.env.REACT_APP_PAYCHOICE_ORIGIN}/scripts/js/widget1.min.js?method=${method}`}
        ></script>
      </Helmet>
      <form onChange={handleMethodChange} className={s.methods}>
        <label>
          <input
            type="radio"
            name="method"
            value="card"
            defaultChecked={method === 'card'}
          />
          Card
        </label>
        <label>
          <input
            type="radio"
            name="method"
            value="bank"
            defaultChecked={method === 'bank'}
          />
          Bank
        </label>
      </form>
      <div key={method}>
        <div
          // @ts-ignore
          widget1=""
          sandbox={
            process.env.REACT_APP_PAYCHOICE_ORIGIN!.includes('sandbox')
              ? 'true'
              : 'false'
          }
          paychoice-account={config.account}
          currency={config.currency}
          amount={config.amount}
          time-stamp={config.timestamp}
          hash={hash}
          payment-method={method === 'card' ? '0' : '1'}
          widget-method="1"
          return-method="1"
          return-action={config.returnAction}
        ></div>
      </div>
    </>
  )
}

export { Paychoice }
